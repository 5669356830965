import React, { useState, useEffect } from "react";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
  } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import { usePrecheckout } from '../context/PreCheckoutContext';
import { useNavigate } from 'react-router-dom';

import { Helmet } from "react-helmet";
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";


// import { Elements } from '@stripe/react-stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.


// Production -
const stripePromise = loadStripe("pk_live_51OBL5KKQDrzSK1373Dg28aENhxIZMyZxULCkUaM7VuwYOtoF1MthjH7h5WR1HJcpqCnoNFohekz9uGrRDhGnCj3l00jmwxBYEk");

// Local
//const stripePromise = loadStripe("pk_test_51OBL5KKQDrzSK137jR0GCQsuQQ0nyjduNxo5D4Ku1UAg6woTcPTMNvb45ZS98M7TopoFjb07Ywytj2qbTdWZQpJS00rKUiEGml")

const CheckoutPage = () => {
    const [clientSecret, setClientSecret] = useState(null);
    // const { user } = useContext(AuthContext);
    const location = useLocation();
    // const { precheckoutId } = usePrecheckout();
    const { precheckoutId, setPrecheckoutId } = usePrecheckout();
    const navigate = useNavigate();

    const first10Rows = location.state?.first10Rows;
    const last10Rows = location.state?.last10Rows;
    const file = location.state?.file;
    const dataFrameLength = location.state?.dataFrameLength
    const inferFreq = location.state?.inferFreq
    const seriesName = location.state?.seriesName

    // console.log("precheckout ID: ", precheckoutId)
    console.log({ precheckout_id: precheckoutId, seriesName: seriesName });


    // Check for undefined precheckoutId and navigate if needed
    if (!first10Rows || !last10Rows || !file) {
      console.log("first10Rows null... redirecting");
      navigate('/')
    }

    useEffect(() => {    
      // Create a Checkout Session as soon as the page loads
      fetch("https://9pz3pqmrru.us-east-2.awsapprunner.com/api/create-payment-intent/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ precheckout_id: precheckoutId,
                               seriesName: seriesName, }), // Include precheckout_id in the request body
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.client_secret))
        .catch((error) => {
          console.error('Error creating payment intent:', error);
          // Handle error
        });
    }, [precheckoutId, file]);

    useEffect(() => {
      const formData = new FormData();
      formData.append('csv_file', file);
      formData.append('precheckout_id', precheckoutId);
  
      fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/upload-and-save-csv/', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                console.log('Upload successful:', data["message"]);
                // Handle success
                })
                .catch(error => {
                console.error('Error during upload:', error);
                // Handle error
                });
    }, [precheckoutId, file]);
  
    return (
      <div className="checkout-page-container">
        <div className="ptf-site-wrapper animsition  ptf-is--about-us">
          <Helmet>
            <title>LikelySpurious - Homepage</title>
          </Helmet>
          {/* End Page SEO Content */}
          <div className="ptf-site-wrapper__inner">
            <HeaderDefault />
            <div className='checkout-dataframe-length'>Series length: {dataFrameLength} </div>
            <div className='checkout-dataframe-freq'>Detected frequency: {inferFreq} </div>
            <div className="checkout-text"><strong className="highlighted-text">LikelySpurious does not provide you with any data</strong>, while most data is in the public domain, you will need to access it from the direct source (we provide links).</div>
            <div className="checkout-text">Following your order completion you should receive an email within 24 hours (can take up to 48 hours) that will allow you to set up an account and access your analysis.</div>
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret}}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
        </div>
        </div>
        <Footer />
      </div>
    )
  }

  export default CheckoutPage;